@use "../../assets/styles/common.module";

.notification {
  &_wrapper {
    display: flex;
    padding: 30px;
    background-color: var(--uk-kit-colors-bgSecondary);
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;

    @media all and (width <= 630px) {
      flex-direction: column;
    }

    @media all and (width >= 768px) and (width <= 950px) {
      flex-direction: column;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;

    @media all and (width <= 630px) {
      order: 1;
    }

    @media all and (width >= 768px) and (width <= 950px) {
      order: 1;
    }
  }

  &_image {
    height: 100%;
    max-height: 110px;
  }

  &_buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
