@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_email {
    font-size: 19px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
  }
}
