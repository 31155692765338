$size_s: 32px;
$size_m: 48px;
$icon_size_s: 16px;
$icon_size_m: 24px;
$social_main_black: #231e1b;
$social_icq_background: #171e1a;
$social_colors: (
  "yandex": #f33,
  "facebook": #4a67ad,
  "linkedin": #3276b0,
  "linkedin-openid-connect": #3276b0,
  "vk": #3278f6,
  "ok": #ee8208,
  "google": #ea4335,
  "twitter": #499ae9,
  "instagram": #499ae9,
  "microsoft": #05a6f0,
  "gitlab": #ed6d2e,
  "github": $social_main_black,
  "paypal": #253b80,
  "stack-overflow": #f58025,
  "tiktok": $social_main_black,
  "unknown": var(--uk-kit-colors-brandTertiary),
  "phone": var(--uk-kit-colors-brandTertiary),
  "telegram": #34aadf,
  "whatsApp": #67c15e,
  "skype": #52aeea,
  "icq": #00d57c,
);

.wrapper {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &_clickable {
    cursor: pointer;
  }
}

// TODO: colors
.border {
  &_var1 {
    border: 1px solid #dfdfdf;
  }

  &_var2 {
    border: 1px solid #dfdfdf;
  }

  &_var3 {
    border: 0;
  }

  &_var4 {
    border: 1px solid var(--uk-kit-colors-white);
  }

  &_var5 {
    border: 1px solid #dfdfdf;
  }
}

.icon {
  display: inline-block;
}

.size {
  &_s {
    width: $size_s;
    height: $size_s;
  }

  &_m {
    width: $size_m;
    height: $size_m;
  }

  &_i {
    &_s {
      width: $icon_size_s;
      height: $icon_size_s;
    }

    &_m {
      width: $icon_size_m;
      height: $icon_size_m;
    }
  }
}

.color {
  &_inherit {
    color: inherit;
  }

  &_white {
    color: var(--uk-kit-colors-white);
  }

  &_tertiary {
    color: var(--uk-kit-colors-interfaceTertiary);
  }

  @each $colorName, $colorVal in $social_colors {
    &_#{$colorName} {
      color: $colorVal;
    }
  }

  &_b {
    &_inherit {
      background-color: inherit;
    }

    &_white {
      background-color: var(--uk-kit-colors-white);
    }

    &_tertiary {
      background-color: var(--uk-kit-colors-interfaceTertiary);
    }

    @each $colorName, $colorVal in $social_colors {
      &_#{$colorName} {
        background-color: $colorVal;
      }
    }

    &_icqCustom {
      background-color: $social_icq_background;
    }
  }
}
