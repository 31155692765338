@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_htmlList {
    text-align: left;

    //margin-top: 15px;
    margin-left: 10px;
  }

  &_htmlListItem {
    color: var(--uk-kit-colors-labelsTertiary);
    margin-left: 10px;

    //margin-top: 10px;

    //&__first {
    //  margin-top: 0;
    //}
  }
}

.serviceItem {
  &_wrapper {
    display: flex;
    padding: 16px;
    background-color: var(--uk-kit-colors-bgSecondary);
    flex-flow: row wrap;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
  }

  &_content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;

    @media all and (width <= 440px) {
      gap: 10px;
    }
  }
}
