@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_phone {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

    @media all and (width <= 490px) {
      flex-direction: column;
    }
  }

  &_phoneContent {
    display: flex;
    flex-direction: row;
    gap: 18px;
  }
}
