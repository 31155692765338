.wrapper {
  display: flex;
  padding: 20px 30px;
  border: 1px solid var(--uk-kit-colors-interfaceQuaternary);

  //width: 50%;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;

  @media all and (width <= 1024px) {
    width: auto;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.footer {
  text-align: end;
  color: var(--uk-kit-colors-brandTertiary);
  margin-top: 30px;
  text-decoration: none;
}

.margin {
  &_s {
    margin-top: 15px;
  }

  &_m {
    margin-top: 20px;
  }
}
