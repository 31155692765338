@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_iconBlock {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
  }
}
