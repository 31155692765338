@use "mixins";
@use "sass:map";

$variables: (
  "size": (
    "headerHeight": 64px,
    "footerHeight": 60px,
    "menuWidth": 270px,
    "menuHeight": 84px,
    "menuHeightMobile": 72px,
    "formWidth": 510px,
    "subHeaderHeight": 56px,
    "pageWidth": 1440px,
    "subPageWidth": 470px,
  ),
  "customize": (
    "headerBackground":
    "linear-gradient(90.07deg, rgba(253, 252, 255, 0.364225) 0.03%, rgba(255, 255, 255, 0.22) 3.17%, #F6F1FF 26.7%, rgba(255, 255, 255, 0.56) 50.23%, rgba(240, 244, 255, 0.6) 72.72%, rgba(253, 252, 255, 0.83) 95.2%), #FFFFFF",
  ),
);

//[data-passport-vars="true"] {
//  @include mixins.generateCssVars($variables);
//}
:root {
  @include mixins.generateCssVars($variables);
}
