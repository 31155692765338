$height: 6px;
$width_1: 20;
$width_2: 55;
$width_3: 100;

.wrapper {
  display: flex;
  flex-direction: column;
}

.scale {
  width: 100%;
  height: $height;
  background-color: var(--uk-kit-colors-bgTertiary);
  border-radius: 4px;
}

.progress {
  height: $height;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &_1 {
    background-color: var(--uk-kit-colors-actionErrorSecondary);
    transform: scaleX($width_1 / 100);
  }

  &_2 {
    background-color: var(--uk-kit-colors-actionPromoSecondary);
    transform: scaleX($width_2 / 100);
  }

  &_3 {
    background-color: var(--uk-kit-colors-actionConfirmPrimary);
    transform: scaleX($width_3 / 100);
  }
}

.description {
  margin-top: 6px;
}
