.list {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  padding: 14px 20px 15px;
  cursor: pointer;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  &:hover {
    background-color: var(--uk-kit-colors-bgSecondary);
  }
}
