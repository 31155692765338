@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_phoneInput {
    div[class*="startContent"] {
      span {
        color: #888c8f;
      }
    }

    div[class*="dropdownContainer"] > div[class*="container"] {
      left: auto;
      z-index: 200;
      width: 250px;
    }

    div[class*="dropdownContainer"] > div[class*="selectButton"] > span {
      width: 30px;
    }

    div[class*="inputContainer"] > input {
      padding: 11px 10px 9px;
    }

    > label > div[class*="endContent"] {
      position: relative;
      width: 80px;
      flex-shrink: 0;
      margin-right: 0;

      div[class*="selectButton"],
      div[class*="selectButton"]:hover {
        position: relative;
        height: 42px;
        border: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        // TODO: colors
        span {
          color: #888c8f;
        }
      }

      div[class*="selectButton"]::before {
        position: absolute;
        left: 0;
        width: 1px;
        height: 60%;
        background-color: #b4aeb9;
        content: "";
      }
    }
  }

  &_dropdownEnd {
    position: fixed;
    z-index: 200;
  }
}
