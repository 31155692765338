@use "../../assets/styles/form.module";
@use "../../assets/styles/common.module";

.this {
  &_iconBlock {
    display: flex;
    padding: 17px 14px;
    background-color: #f7f7fa;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    border-radius: 8px;
  }

  &_head {
    text-align: center;
  }
}

// TODO: colors
.notification {
  &_wrapper {
    display: flex;
    background: #fff;
    flex-direction: row;
    border-radius: 6px;
    box-shadow:
      0 12.7317px 17.869px rgb(131 112 144 / 5.96%),
      0 3.7906px 5.3201px rgb(131 112 144 / 4.04%);

    &::before {
      width: 3px;
      height: auto;
      margin: 4px;
      background: var(--uk-kit-colors-brandTertiary);
      content: " ";
      float: left;
      border-radius: 4px;
    }
  }

  &_content {
    display: flex;
    padding: 14px;
    flex-direction: column;
  }
}
