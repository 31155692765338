@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_table__td {
    padding-top: 30px;

    @media all and (width <= 530px) {
      padding-top: 0;
    }
  }

  &_text {
    margin-right: 20px;
  }

  &_table_tr {
    @media all and (width <= 530px) {
      display: flex;
      flex-direction: column;
      gap: 16px;
      min-width: max-content;
    }
  }

  &_table_pad {
    @media all and (width <= 530px) {
      padding-top: 20px;
    }
  }
}
