@use "sass:meta";
@import "constants";

@mixin generateCssVars($theme) {
  @each $type, $variables in $theme {
    @each $name, $value in $variables {
      $cssValue: meta.inspect($value);

      #{$pcCustomVariablePrefix}-#{$type}-#{$name}: $cssValue;
    }
  }
}
