.page {
  &_wrapper {
    display: flex;

    //max-width: 870px;
    height: fit-content;
    padding: 30px;
    flex-direction: column;

    //padding-bottom: 20px;

    &__sub {
      //max-width: 480px;
      //@media all and (max-width: 768px) {
      //  margin: 0 auto;
      //}
    }

    @media all and (width <= 375px) {
      padding: 15px 20px;
    }
  }

  &_block {
    display: flex;
    flex-direction: column;

    &__border {
      padding: 30px;
      border: 1px solid var(--uk-kit-colors-interfaceQuaternary);
      border-radius: 8px;

      @media all and (width <= 375px) {
        padding: 20px;
      }
    }

    &__row {
      flex-direction: row;
    }

    &__smallBorder {
      padding: 10px;
      border: 1px solid var(--uk-kit-colors-interfaceQuaternary);
      border-radius: 8px;
    }
  }

  &_darkBlock {
    display: flex;
    padding: 16px 26px;
    background-color: var(--uk-kit-colors-bgSecondary);
    flex-direction: column;
    border-radius: 4px;
  }

  &_iconBlock {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;

    &__border {
      padding: 30px;
      border: 1px solid var(--uk-kit-colors-interfaceQuaternary);
      border-radius: 8px;
    }

    &__small {
      padding: 20px;
    }

    &__top {
      align-items: flex-start;
    }
  }

  &_iconBlock__icon {
    flex-shrink: 0;
  }

  &_imageBlock {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

    &__border {
      padding: 30px;
      border: 1px solid var(--uk-kit-colors-interfaceQuaternary);
      border-radius: 8px;
    }

    @media all and (width <= 1060px) {
      flex-direction: column;
    }
  }

  &_imageBlock__image {
    height: 100%;
    max-height: 110px;

    //width: fit-content;
  }

  &_image {
    height: 100%;
  }
}
