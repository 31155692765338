@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_wrapperBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media all and (width <= 460px) {
      flex-direction: column;
    }
  }

  &_button {
    @media all and (width <= 460px) {
      align-self: end;
    }
  }
}
