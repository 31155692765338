@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_choseReason {
    font-size: 16px !important;
  }

  &_wrapping {
    white-space: pre-line;
  }
}
