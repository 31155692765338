@use "../../assets/styles/common.module";
@use "../../assets/styles/page.module";

.social {
  &_firstColumn {
    display: flex;
    align-items: center;
  }

  &_comboColumn {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  &_contentColumn {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &_icon {
    display: flex;
    align-items: center;
  }

  &_iconColumn {
    margin-right: 20px;
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &_column1 {
    @media all and (width <= 680px) {
      min-width: auto !important;
    }

    @media all and (width >= 768px) and (width <= 1000px) {
      min-width: auto !important;
    }
  }

  &_cells {
    @media all and (width <= 680px) {
      flex-direction: row !important;
    }

    @media all and (width >= 768px) and (width <= 1000px) {
      flex-direction: row !important;
    }
  }
}

.protection {
  &_secondColumn {
    display: flex;
    align-items: center;
  }

  &_icon {
    margin-right: 10px;
  }
}

.sessionsInfo {
  &_icon {
    display: flex;
    width: 40px;
    height: 40px;
    background-color: var(--uk-kit-colors-bgSecondary);
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &_firstColumn {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &_secondColumn {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

.list {
  &_wrapper {
    display: flex;
    flex-direction: column;
  }

  &_row {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    &__divider {
      padding-top: 20px;
      border-top: 1px solid var(--uk-kit-colors-interfaceQuaternary);
    }

    &__last {
      padding-bottom: 0;
    }
  }
}

.sessionsInfoList {
  &_session {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &_clients {
    display: flex;
    overflow: hidden;
    margin-top: 4px;
  }

  &_bottom {
    display: flex;
    flex-flow: row wrap;
    gap: 4px;
    margin-top: 14px;
  }

  &_ip {
    margin-right: 16px;
  }
}

.sessionsList {
  &_head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  &_currentSession {
    display: flex;
    height: fit-content;
    flex-direction: row;
    gap: 6px;
    align-items: center;
  }

  &_item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;
    word-break: break-word;

    &__last {
      margin-bottom: 0;
    }
  }

  &_clients {
    display: flex;
    overflow: hidden;
    margin-top: 4px;
  }

  &_bottom {
    display: flex;
    flex-flow: row wrap;
    gap: 4px;
    margin-top: 14px;
  }
}

.twoColumnsTable {
  &_column1 {
    min-width: 200px;

    @media all and (width <= 600px) {
      min-width: auto;
    }
  }

  &_column1__xs {
    display: flex;
    width: 140px;

    @media all and (width <= 600px) {
      width: 100%;
    }
  }

  &_column2 {
    display: flex;
    width: 100%;
    overflow: hidden;
    align-items: center;
    margin-left: 30px;

    @media all and (width <= 680px) {
      margin-left: 0;
    }

    @media all and (width >= 768px) and (width <= 1000px) {
      margin-left: 0;
    }
  }

  &_column2__xs {
    display: flex;
    width: 100%;
    overflow: hidden;
    margin-left: 14px;

    @media all and (width <= 600px) {
      margin-left: 0;
    }
  }

  &_cells {
    //flex: 1 1 100%;
    overflow: hidden;

    @media all and (width <= 680px) {
      flex-direction: column !important;
      gap: 6px;
    }

    @media all and (width >= 768px) and (width <= 1000px) {
      flex-direction: column !important;
      gap: 6px;
    }
  }

  &_cells__xs {
    overflow: hidden;
    align-items: baseline;

    @media all and (width <= 600px) {
      flex-direction: column !important;
      gap: 14px;
    }
  }
}

.threeColumnsTable {
  &_column1 {
    display: flex;
    align-items: center;
    min-width: 200px;
    max-width: 200px;

    @media all and (width <= 1060px) {
      min-width: auto;
      max-width: none !important;
    }
  }

  &_column2 {
    display: flex;
    align-items: center;
    min-width: 200px;
    max-width: 200px;
    margin-left: 10px;

    @media all and (width <= 1060px) {
      min-width: auto;
      max-width: none !important;
      margin-left: 0;
    }
  }

  &_column3 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
    margin-left: 10px;

    @media all and (width <= 1060px) {
      justify-content: flex-start;
      margin-left: 0;
    }
  }

  &_cells {
    @media all and (width <= 1060px) {
      flex-direction: column !important;
      gap: 6px;
    }
  }
}

.listTable {
  &_column0 {
    width: 40px;
    height: 40px;

    @media all and (width <= 1240px) {
      visibility: hidden;
    }
  }

  &_column1 {
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    min-width: 260px; // 280px
    max-width: 260px;
    margin-left: 14px;

    //> :nth-child(n+2) {
    //  margin-top: 4px;
    //}

    @media all and (width <= 1240px) {
      min-width: auto;
      max-width: none !important;
      margin-top: 10px;
      margin-left: 0;
    }
  }

  &_column2 {
    display: flex;
    text-align: left;
    flex-direction: column;
    min-width: 300px;
    max-width: 300px;
    margin-left: 14px;

    //> :nth-child(n+2) {
    //  margin-top: 6px;
    //}
    @media all and (width <= 1240px) {
      min-width: auto;
      max-width: none !important;
      margin-left: 0;
    }
  }

  &_column3 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
    margin-left: 10px;

    @media all and (width <= 1240px) {
      justify-content: flex-start;
      margin-left: 0;
    }
  }
}

.smallTable {
  &_column1 {
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    min-width: 280px; // 280px
    max-width: 280px;

    &__t2 {
      min-width: 140px;
      max-width: 140px;
    }

    @media all and (width <= 900px) {
      min-width: auto;
      max-width: none !important;
    }
  }

  &_column2 {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-left: 10px;

    &__t2 {
      justify-content: flex-start;
    }

    @media all and (width <= 900px) {
      justify-content: flex-start;
      margin-top: 10px;
      margin-left: 0;
    }
  }

  &_cells {
    @media all and (width <= 900px) {
      flex-direction: column !important;
    }
  }
}

.history {
  &_column {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

.socialTable {
  &_column1 {
    display: flex;
    width: 210px;

    @media all and (width <= 680px) {
      width: 52px;
    }

    @media all and (width >= 768px) and (width <= 1000px) {
      width: 52px;
    }
  }

  &_column2 {
    display: flex;
    width: 100%;
    overflow: hidden;
    align-items: center;
    margin-left: 10px;

    @media all and (width <= 680px) {
      margin-left: 0;
    }

    @media all and (width >= 768px) and (width <= 1000px) {
      margin-left: 0;
    }
  }

  &_cells {
    overflow: hidden;

    //@media all and (max-width: 680px) {
    //  flex-direction: column !important;
    //}
    //@media all and (min-width: 768px) and (max-width: 1000px) {
    //  flex-direction: column !important;
    //}
  }
}
