@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &_listRow {
    display: flex;
    flex-direction: row;
    gap: 20px;

    @media all and (width <= 1024px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &_singleItem {
    margin-right: calc(50% + 10px);

    @media all and (width <= 1024px) {
      margin-right: 0;
    }
  }
}
