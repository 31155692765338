@use "../../assets/styles/form.module";
@use "../../assets/styles/common.module";

.this {
  &_comboItem {
    margin-left: 10px;
  }

  &_dropdown {
    &__restriction {
      min-width: 200px;

      @media all and (width <= 450px) {
        min-width: 150px;
      }
    }

    &__error {
      div[class*="selectButton"] {
        border: 1px solid var(--uk-kit-colors-actionErrorSecondary);
      }

      span[class*="placeholderText"] {
        color: var(--uk-kit-colors-actionErrorSecondary);
      }
    }
  }
}
