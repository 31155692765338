@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_socialRow {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
  }
}
