.root {
  position: fixed;
  top: calc(50vh - 50px);
  left: calc(50% - 50px);
}

.backdrop {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgb(250 250 250 / 27%);
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
  visibility: hidden;
  backdrop-filter: blur(3px);

  &_active {
    opacity: 1;
    visibility: visible;
  }
}
