@use "sass:map";
@use "@design-system/ui-kit/src/assets/styles/themes/themes";
@use "../../assets/styles/common.module";
@use "../../assets/styles/functions";

:export {
  colorbrandtertiary: map.get(map.get(themes.$themeConvertSocialLight, "colors"), "brandTertiary");
  colorinterfacesecondary: map.get(map.get(themes.$themeConvertSocialLight, "colors"), "interfaceSecondary");
}

.this {
  &_wrapper {
    position: fixed;
    background-color: var(--uk-kit-colors-white);
    overflow-y: auto;
    max-height:
      calc(
        100vh - #{functions.cssVar(size, headerHeight)} - 30px * 2 - #{functions.cssVar(size, footerHeight)}
      );

    @media all and (width <= 768px) {
      top: functions.cssVar(size, headerHeight);
      left: 0;
      width: 100%;
      overflow: auto hidden;
      max-height: functions.cssVar(size, menuHeight);
      border-top: 1px solid var(--uk-kit-colors-bgTertiary);
      border-bottom: 1px solid var(--uk-kit-colors-bgTertiary);
    }

    @media all and (width <= 600px) {
      max-height: functions.cssVar(size, menuHeightMobile);
    }
  }

  &_root {
    display: flex;
    width: functions.cssVar(size, menuWidth);
    height: max-content;
    flex-direction: column;

    @media all and (width <= 768px) {
      width: 100%;
      padding: 21px 40px;
      flex-direction: row;
    }

    @media all and (width <= 600px) {
      padding: 14px 15px;
    }
  }

  &_margin {
    margin-top: 6px;

    @media all and (width <= 768px) {
      margin-top: 0;
      margin-left: 6px;
    }
  }

  &_item {
    display: flex;
    width: fit-content;
    padding: 10px 20px 10px 14px;
    cursor: pointer;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    text-decoration: none;
    border-radius: 8px;

    &:hover {
      background-color: var(--uk-kit-colors-bgSecondary);
    }

    &__isActive {
      background-color: var(--uk-kit-colors-bgSecondary);
    }

    &__notFirst {
      margin-top: 6px;

      @media all and (width <= 768px) {
        margin-top: 0;
        margin-left: 6px;
      }
    }
  }

  &_scrollHelper {
    position: fixed;
    top: functions.cssVar(size, headerHeight);
    right: 0;
    display: flex;
    width: 65px;
    height: functions.cssVar(size, headerHeight);
    background: linear-gradient(270deg, #fff 62.74%, rgb(255 255 255 / 0%) 114.44%);
    align-items: center;
    justify-content: flex-end;

    &__hide {
      display: none;
    }

    @media all and (width <= 600px) {
      height: functions.cssVar(size, menuHeightMobile);
    }

    @media all and (width >= 768px) {
      display: none;
    }
  }

  &_hand {
    margin-right: 10px;

    @media all and (width >= 768px) {
      display: none;
    }
  }

  &_hr {
    padding: 10px;
    flex-shrink: 0;
    margin-top: 6px;

    > * {
      width: 100px;
      margin: 0;
      background-color: var(--uk-kit-colors-labelsQuaternary);
      opacity: 0.4;
      border-color: var(--uk-kit-colors-labelsQuaternary);
      border-style: solid;
      border-width: 0;
      border-bottom-width: thin;
      border-radius: 4px;
    }

    @media all and (width <= 768px) {
      display: none;
    }
  }

  &_vl {
    width: 1px;
    height: 24px;
    margin: 10px !important;
    background-color: var(--uk-kit-colors-labelsQuaternary);
    opacity: 0.4;
    flex-shrink: 0;
    border-radius: 4px;

    @media all and (min-width: 768px + 1px) {
      display: none;
    }
  }
}
