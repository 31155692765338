@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_pageWrapper {
    height: 100%;
    justify-content: center;
  }

  &_wrapper {
    display: grid;
    margin: 0 auto;
    grid-template-columns: max-content 1fr;
    gap: 40px;

    img[class*="page_image"] {
      height: auto;
      max-width: 100%;
    }

    @media all and (width <= 768px) {
      grid-template-columns: 1fr;
      justify-items: center;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }

  &_header {
    font-size: 36px !important;
    font-weight: 500 !important;
    line-height: 44px !important;
  }
}
