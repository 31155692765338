@use "../../assets/styles/common.module";
@use "../../assets/styles/functions";

$bgImg: url("../../assets/images/background.jpg");
$formWidthMax: calc(#{functions.getConst(size, formWidth)} + (15px * 2));

.this {
  &_background {
    background: center center var(--uk-kit-colors-bgPrimary) $bgImg no-repeat;
    background-size: cover;
  }

  &_main {
    display: flex;
    width: 100%;
    overflow-y: hidden;
    flex-direction: column;
    min-height: calc(100vh - #{functions.cssVar(size, headerHeight)} - #{functions.cssVar(size, footerHeight)});
    margin-top: functions.cssVar(size, headerHeight);
  }

  &_content {
    width: functions.cssVar(size, formWidth);
    margin: 0 auto;
    overflow-x: hidden;

    @media all and (max-width: $formWidthMax) {
      width: auto;
      margin: 0 15px;
    }
  }

  &_center {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &_form {
    width: inherit;
    height: fit-content;
    border: 1px solid var(--uk-kit-colors-interfaceQuaternary);
    background: linear-gradient(135deg, #fff 0%, #fff 100%);
    border-radius: 14px;
    box-shadow: 0 6.6501px 5.3201px rgb(0 0 0 / 2.83%);
  }

  &_support {
    display: flex;
    flex-flow: row wrap;
    gap: 6px;

    //white-space: pre;
    align-items: center;
    justify-content: center;
  }

  &_notification {
    width: functions.cssVar(size, formWidth);
    margin: 40px auto 0;

    @media all and (max-width: $formWidthMax) {
      width: auto;
      margin: 40px 15px 0;
    }
  }
}
