@use "../../assets/styles/form.module";
@use "../../assets/styles/common.module";

.this {
  &_head {
    text-align: center;
  }

  &_footer {
    text-align: center;
  }

  &_table {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    margin-top: 10px;

    &__count1 {
      padding-right: 0;
    }

    &__count2 {
      padding-right: 10px;
    }

    &__count3 {
      padding-right: 20px;
    }

    &__count4 {
      padding-right: 30px;
    }

    &__count5 {
      padding-right: 40px;
    }

    > :nth-child(2) {
      left: 10px;
    }

    > :nth-child(3) {
      left: 20px;
    }

    > :nth-child(4) {
      left: 30px;
    }

    > :nth-child(5) {
      left: 40px;
    }
  }

  &_card {
    position: relative;
    display: table-cell;
    padding: 10px;
    border: 1px solid var(--uk-kit-colors-interfaceQuaternary);
    transition: border ease-in-out 0.2s;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      border: 1px solid var(--uk-kit-colors-brandTertiary);
    }

    &__selected {
      border: 1px solid var(--uk-kit-colors-brandTertiary);
    }
  }

  &_cardContent {
    display: flex;
    flex-flow: column nowrap;
  }

  &_iconDevice {
    align-self: center;
    border-radius: 50%;
  }

  // TODO: colors
  &_card:hover &_iconDevice {
    background-color: #f5f4ff;
  }

  &_iconDone {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &_label {
    width: inherit;
    text-align: center;
    overflow: hidden;
    margin-top: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
