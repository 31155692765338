@use "functions";

.form {
  &_wrapper {
    display: flex;
    height: fit-content;
    padding: 40px;
    flex-direction: column;
    max-width: 100%;

    @media all and (width <= calc(#{functions.cssVar(size, formWidth)} + (15px * 2))) {
      padding: 15px;
    }
  }

  &_head {
    display: flex;
    margin: 0 20px;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}
