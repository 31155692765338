@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_watchMore {
    align-self: end;
  }

  &_firstColumn {
    display: flex;
    flex-direction: column;
  }

  &_currentSession {
    display: flex;
    height: fit-content;
    flex-direction: row;
    gap: 6px;
    align-items: center;
  }

  &_histryColumn {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &_marginBottom {
    margin-bottom: 20px;
  }

  &_test {
    > div:nth-child(5n) {
      border-bottom: 1px solid #000;
    }
  }
}
