@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_column {
    display: flex;
    flex-direction: column;
  }

  &_table {
    padding: 20px;
    background-color: var(--uk-kit-colors-bgSecondary);
  }
}
