$size_s: 32px;
$size_m: 48px;

.wrapper {
  display: flex;
  padding: 10px 14px;

  //background-color: var(--uk-kit-2-colors-backgroundTertiary);
  background-color: #f7f7fa;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.avatar {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.size {
  &_s {
    width: $size_s;
    height: $size_s;
  }

  &_m {
    width: $size_m;
    height: $size_m;
  }
}

.color {
  &_seventh {
    border: 1px solid #29272b;

    //background-color: var(--uk-kit-2-colors-seventh);
    background: #29272b;
  }

  &_backgroundTertiary {
    border: 1px solid #97d9fb;

    // TODO: colors
    background-color: #aee2ff;
  }
}

.cropText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
