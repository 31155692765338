@use "../../assets/styles/form.module";
@use "../../assets/styles/common.module";

.this {
  &_footer {
    text-align: center;
  }

  &_step {
    display: flex;
    flex-direction: column;
  }

  &_nativePlaceholder {
    input::placeholder {
      color: var(--uk-kit-colors-labelsQuaternary) !important;
    }
  }
}
