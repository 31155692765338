.languageDropdown {
  display: flex;
  flex-direction: column;
  margin-top: 19px;
}

.list {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  padding: 14px 20px 15px;
  cursor: pointer;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: var(--uk-kit-colors-bgSecondary);
  }

  &_checked {
    cursor: unset;

    &:hover {
      background-color: inherit;
    }
  }
}

.icon {
  margin-right: 16px;
}
