@use "../../assets/styles/common.module";
@use "../../assets/styles/functions";

.this {
  &_head {
    position: fixed;
    z-index: 300;
    width: 100%;
    border-bottom: 1px solid var(--uk-kit-colors-bgTertiary);
  }

  &_subHeader {
    //z-index: 100;
    top: functions.cssVar(size, headerHeight);
    display: flex;
    width: 100%;
    height: functions.cssVar(size, subHeaderHeight);
    margin: 0 auto;
    background-color: var(--uk-kit-colors-bgPrimary);
    flex-direction: row;
    align-items: center;
    max-width: functions.cssVar(size, pageWidth);
  }

  &_subHeaderContent {
    display: flex;
    width: 100%;
    padding: 0 10px;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-left: 30px;

    @media all and (width <= 375px) {
      padding: 0 15px;
    }

    @media all and (width >= 540px) {
      max-width: calc(#{functions.cssVar(size, subPageWidth)} + 30px * 2);
    }

    @media all and (width <= 768px) {
      margin: 0 auto;
    }

    @media all and (width <= 485px) {
      justify-content: left;
    }
  }

  &_main {
    display: flex;
    width: 100%;
    margin: functions.cssVar(size, headerHeight) auto 0;
    flex-direction: row;
    max-width: functions.cssVar(size, pageWidth);
    min-height: calc(100vh - #{functions.cssVar(size, headerHeight)} - #{functions.cssVar(size, footerHeight)});

    &.subHead {
      min-height:
        calc(
          100vh - #{functions.cssVar(size, headerHeight)} - #{functions.cssVar(size, footerHeight)} - #{functions.cssVar(size, subHeaderHeight)}
        );
      margin-top: calc(#{functions.cssVar(size, headerHeight)} + #{functions.cssVar(size, subHeaderHeight)});
    }

    @media all and (width <= 768px) {
      flex-direction: column;
    }
  }

  &_menu {
    z-index: 10;
    padding: 30px 0 30px 40px;
    min-width: calc(#{functions.cssVar(size, menuWidth)} + 40px);

    @media all and (width <= 768px) {
      height: functions.cssVar(size, menuHeight);
      min-width: 100%;

      &.subHead {
        display: none;
      }
    }
  }

  &_page {
    width: 100%;
    overflow: hidden;
    flex: 0 1 auto;

    //padding: 30px 40px;

    @media all and (width <= 768px) {
      margin-left: 0;
    }
  }

  &_page__sub {
    @media all and (width >= 540px) {
      max-width: calc(#{functions.cssVar(size, subPageWidth)} + 30px * 2) !important;
    }

    @media all and (width <= 768px) {
      margin: 0 auto;
    }
  }
}
