.list {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  padding: 20px 16px;
  flex-direction: row;
  align-items: center;

  &_hover {
    &:hover {
      background-color: var(--uk-kit-colors-bgSecondary);
    }
  }

  &_action {
    user-select: none;
    text-decoration: none;

    &:hover {
      cursor: pointer;

      //opacity: .6;
    }
  }

  &_divider {
    border-top: 1px solid var(--uk-kit-colors-interfaceQuaternary);
  }
}

.icon {
  cursor: pointer;
  flex-shrink: 0;
}

.cells {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-grow: 1;
}
