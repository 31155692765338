@use "@design-system/ui-kit/src/assets/styles/themes/themes";
@use "sass:map";

:export {
  interfaceAdPrimary: map.get(map.get(themes.$themeConvertSocialLight, "colors"), "brandTertiary");
}

.initials {
  background-color: transparent;
}
