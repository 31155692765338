@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_pageWrapper {
    height: 100%;
    justify-content: center;
  }

  &_wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 40px;
    justify-content: center;
  }

  &_content {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }

  &_header {
    font-size: 36px !important;
    font-weight: 500 !important;
    line-height: 44px !important;
  }
}
