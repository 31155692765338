.wrapper {
  display: flex;
  flex-direction: column;
}

.margin {
  &__xxs {
    margin-top: 5px;
  }

  &__xs {
    margin-top: 10px;
  }

  &__s {
    margin-top: 15px;
  }
}

.break {
  word-break: break-word;
}
