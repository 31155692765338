@use "../../assets/styles/page.module";
@use "../../assets/styles/common.module";

.this {
  &_profilesList {
    display: flex;
    flex-flow: row wrap;
    gap: 15px;
  }
}
