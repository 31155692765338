.common {
  &_icon {
    cursor: pointer;
  }

  &_column {
    display: flex;
    flex-direction: column;
  }

  &_row {
    display: flex;
    flex-direction: row;
  }

  &_textNewLine {
    display: flex;
    flex-direction: column;
  }

  &_noticeContent {
    display: flex;
    flex-direction: column;
  }

  &_noticeContentItem {
    margin-bottom: 20px;
  }

  &_margin {
    &__xxs {
      margin-top: 5px;
    }

    &__xs {
      margin-top: 10px;
    }

    &__s {
      margin-top: 15px;
    }

    &__m {
      margin-top: 20px;
    }

    &__l {
      margin-top: 30px;
    }

    &__xl {
      margin-top: 35px;
    }

    &__xxl {
      margin-top: 40px;
    }
  }

  &_iconMargin {
    &__s {
      margin-right: 8px;
    }

    &__m {
      margin-right: 10px;
    }

    &__l {
      margin-right: 14px;
    }
  }

  &_buttons {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    justify-content: flex-end;
    margin-top: 30px;

    &__center {
      justify-content: center;
    }

    @media all and (width <= 768px) {
      gap: 15px;
    }
  }

  &_link {
    cursor: pointer;
    text-decoration: none;
  }

  &_htmlList {
    text-align: left;
    margin-left: 10px;
  }

  &_htmlListItem {
    color: var(--uk-kit-colors-labelsTertiary);
    margin-left: 10px;

    //margin-top: 10px;

    //&__first {
    //  margin-top: 0;
    //}
  }

  &_cropText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
