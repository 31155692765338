@use "../../assets/styles/common.module";
@use "../../assets/styles/functions";

.this {
  &_wrapper {
    display: flex;
    width: 100%;
    height: functions.cssVar(size, footerHeight);
    padding: 20px 10px;
    background-color: var(--uk-kit-colors-bgSecondary);
    align-items: center;
    justify-content: center;

    &__form {
      background: initial;
    }
  }
}
