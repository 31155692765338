@use "../../assets/styles/common.module";
@use "../../assets/styles/functions";

.this {
  &_wrapper {
    z-index: 100;
    width: 100%;
    height: functions.cssVar(size, headerHeight);
    background: functions.cssVar(customize, headerBackground);

    &__fixed {
      position: fixed;
    }

    // TODO: colors
    &__form {
      background: #fff;
    }
  }

  &_container {
    display: flex;
    height: inherit;
    margin: 0 auto;
    padding: 17px 40px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: functions.cssVar(size, pageWidth);

    @media all and (width <= 600px) {
      padding: 17px 15px;
    }
  }

  &_logo {
    display: flex;
    transition: opacity 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
    align-items: center;
    text-decoration: none;

    &__clickable {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &_menu {
    display: flex;
    align-items: center;
  }

  // TODO: add to ui-kit
  &_logoText {
    font-size: 20px !important;
    font-weight: 900 !important;
    line-height: 23px !important;
    font-style: normal !important;
  }

  &_userDropdown {
    display: flex;
    flex-direction: column;
    margin-top: 7px;
  }
}
