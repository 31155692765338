.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrowIcon {
  transition: transform ease-in-out 0.2s;
  cursor: pointer;

  &_rotated {
    transform: rotate(180deg);
  }
}

.backdrop {
  position: absolute;
  top: 100%;
  right: 0;
  width: 232px;
  background-color: var(--uk-kit-colors-bgPrimary);
  overflow-y: auto;
  max-height: 400px;
  border-radius: 0 0 12px 12px;
  box-shadow:
    0 3px 4px rgb(0 0 0 / 5%),
    0 5px 10px rgb(0 0 0 / 8%);
}

.component {
  margin-right: 8px;

  &_withoutArrow {
    cursor: pointer;
  }
}
